.login_page_section{
    padding: 40px 0px;
}

.login_form_main_div{
    background-color: #f8f8f8;
    border: 1px solid var(--primary-color);
    padding: 20px 10px 15px 10px;
    border-radius: 10px;
}

.login_title_div{
    text-align: center;
    margin-bottom: 15px;
}

.login_img_box > img{
    width: 60px;
}

.login_title_div > h1{
    font-size: 20px;
    color: var(--black-color);
    font-weight: 700;
    text-transform: capitalize;
    margin-top: 8px;
}

.login_form_main_div label{
    font-size: 15px;
    font-weight: 600;
    color: var(--black-color);
    margin-bottom: 4px !important;
}

.login_form_main_div input{
    border-radius: 6px !important;
    outline: none !important;
    border: 1px solid var(--gray-color);
    box-shadow: none !important;
    margin-bottom: 4px;
}


.login_form_main_div ::placeholder{
    font-size: 14px;
    color: var(--black-color) !important;
    font-weight: 600;
}

.login_term_txt {
    padding-left: 15px;
}

.login_term_txt > p{
    color: var(--black-color);
    font-size: 14px;
    font-weight: 600;
    margin-top: 7px;
}

.login_term_txt > p > a{
    color: var(--primary-color) !important;
}

.login_btn > button{
    color: var(--white-color) !important;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 15px;
    border: 1px solid var(--primary-color);
    padding: 7px 10px 5px 10px;
    background-color: var(--primary-color) !important;
    border-radius: 7px !important;
    box-shadow: none !important;
    outline: none !important;
}

.login_btn > button:hover{
    background-color: var(--white-color) !important;
    color: var(--primary-color) !important;
}


/* Default button styling */
.common_button {
    padding: 10px 20px !important;
    font-size: 16px !important;
    border: none !important;
    border-radius: 5px !important;
    transition: background-color 0.3s, cursor 0.3s !important;
  }
  
  /* Disabled button styles */
  .common_button.disabled {
    background-color: #78787e !important; /* Gray-300 */
    color: rgb(255 249 249) !important;
    cursor: not-allowed !important;
  }
  
  /* Enabled button styles */
  .common_button.enabled {
    background-color: #3085d6 !important;
    color: rgb(255 249 249) !important;
    cursor: pointer !important;
}
  