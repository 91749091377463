col {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

.row {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}

.Form_Field {
  margin-bottom: 10px;
}

.Form_Field_Heading {
  font-weight: 600;
  font-size: 16px;
}

.Form_Field_Heading span {
  color: red;
  padding: 0px 5px;
}

.Form_Field_Submit_Button {
  display: flex;
  justify-content: center;
}

.Form_Field_Submit_Button button {
  outline: none;
  border: 1px solid lightgray;
  border-radius: 2px;
  padding: 5px 10px;
}

.Form_Field_Input {
  outline: none;
  border: 1px solid lightgray;
  border-radius: 2px;
  padding: 5px 10px;
  width: 100%;
}

.Form_Field_Input_Array {
  outline: none;
  border: 1px solid lightgray;
  border-radius: 2px;
  padding: 5px 10px;
  width: 90%;
  margin-bottom: 10px;
}

.is-active {
  background-color: #007bff; /* Highlight color */
  color: white; /* Text color */
}
