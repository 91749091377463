.table_user_data Table thead tr th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  font-size: 14px;
}

.table_user_data Table tbody tr td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  font-size: 12px;
}
