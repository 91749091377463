.addbutton {
  padding: 5px 10px;
  border: 1px solid #01a9ff;
  border-radius: 5px;
  font-weight: 600;
}

.Testimonial_ImageDiv {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #01a9ff;
  padding: 2px;
  overflow: hidden;
}

.Testimonial_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.action_button_view {
  font-size: 18px;
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
  cursor: pointer;
}

.action_button_view:hover {
  transform: scale(1.1);
  color: #ff9800; /* Changes color to orange on hover */
}

.action_button_update {
  font-size: 18px;
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
  cursor: pointer;
}

.action_button_update:hover {
  transform: scale(1.1);
  color: lightgreen; /* Changes color to orange on hover */
}

.action_button_update .text-success:hover {
  color: pink; /* Adjusts the color for the pencil icon */
}

.action_button_delete {
  font-size: 18px;
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
  cursor: pointer;
}

.action_button_delete:hover {
  transform: scale(1.1);
  color: red; /* Changes color to orange on hover */
}
